
import { Vue, Options } from 'vue-class-component';
import Spinner from '@/components/Spinner.vue';

type MensaList = {
  city: string;
  mensas: string[];
}

@Options({
  components: {
    Spinner
  }
})
export default class SelectMensaView extends Vue {
  mensaLists: MensaList[] = this.loadFromStorage();

  mounted() {
    this.loadData();
  }

  loadData() {
    if (this.mensaLists.length === 0) {
      fetch('https://api.ericbiedert.de/mensa/list')
        .then(res => res.json())
        .then((data: MensaList[]) => {
          this.mensaLists = data;
          this.saveToStorage();
        });
    }
  }

  saveToStorage() {
    const date = Date.now();
    const data = { date, mensaLists: this.mensaLists }
    localStorage.setItem('mensaLists', JSON.stringify(data));
  }

  loadFromStorage(): MensaList[] {
    const data = JSON.parse(localStorage.getItem('mensaLists') ?? 'null');
    if (data !== null) {
      const { date, mensaLists } = data;
      const now = Date.now();
      // Max age of cache is 12 hours
      if (now - date < 12 * 60 * 60 * 1000) {
        return mensaLists;
      } else {
        localStorage.removeItem('mensaLists');
      }
    }
    return [];
  }

  selectMensa(mensa: string) {
    this.$router.push({ path: `/menu/${encodeURI(mensa)}` });
    localStorage.setItem("selectedMensa", mensa);
  }

  refresh() {
    this.mensaLists = [];
    this.loadData();
  }
}
