
import { Vue, Options} from 'vue-class-component';
import IconButton from './components/IconButton.vue';

@Options({
  components: {
    'icon-button': IconButton
  }
})
export default class App extends Vue {
  theme = localStorage.getItem("theme") ?? 'light';

  created() {
    // Set theme-color meta tag
    const themeColorEl = Array.from(document.head.getElementsByTagName('meta')).find(meta => meta.name == 'theme-color')
    if (themeColorEl) {
      themeColorEl.content = this.theme == 'dark' ? '#242424' : '#fff'
    }

    if (this.$route.path === '/') {
      const selectedMensa = localStorage.getItem('selectedMensa');
      if (selectedMensa !== null) {
        this.$router.push({ path: `/menu/${encodeURI(selectedMensa)}` });
      }
    }
  }

  back() {
    const content = this.$refs.content as any;
    content.onBack();

    this.$router.push({ path: '/' })
  }

  toggleDarkMode() {
    this.theme = (this.theme == 'dark' ? 'light' : 'dark');
    localStorage.setItem("theme", this.theme);

    // Set theme-color meta tag
    const themeColorEl = Array.from(document.head.getElementsByTagName('meta')).find(meta => meta.name == 'theme-color')
    if (themeColorEl) {
      themeColorEl.content = this.theme == 'dark' ? '#242424' : '#fff'
    }
  }

  refresh() {
    const content = this.$refs.content as any;
    content.refresh();
  }
}
