import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mensa-list-wrapper"
}
const _hoisted_2 = { class: "city" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.mensaLists.length === 0)
          ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fadeslide" }, {
      default: _withCtx(() => [
        (_ctx.mensaLists.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mensaLists, ({ city, mensas }) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "city-wrapper",
                  key: city
                }, [
                  _createElementVNode("h2", _hoisted_2, _toDisplayString(city), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mensas, (mensa) => {
                    return (_openBlock(), _createElementBlock("button", {
                      class: "mensa",
                      onClick: ($event: any) => (_ctx.selectMensa(mensa)),
                      key: mensa
                    }, _toDisplayString(mensa), 9, _hoisted_3))
                  }), 128))
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}