
import { Vue, Options } from 'vue-class-component';
import { Swiper, SwiperSlide} from 'swiper/vue'
import Spinner from '@/components/Spinner.vue';
import 'swiper/scss';

type Meal = {
  name: string;
  location: string;
  prices: number[];
}

type Menu = {
  date: string;
  meals: Meal[];
}

type Mensa = {
  name: string;
  menus: Menu[];
}

@Options({
  components: {
    Spinner,
    Swiper,
    SwiperSlide
  },
  props: {
    mensaName: {
      type: String,
      required: true,
    },
  }
})
export default class MenuView extends Vue {
  mensaName!: string;

  error: string | null = null;
  mensa: Mensa | null = null;
  lastUpdated: string | null = null;
  hideSpinner = false; // Used only when going back in history
  slidesPerView = 1;

  created() {
    this.loadFromStorage()
    this.onResize();
  }

  mounted() {
    this.loadData();
    addEventListener('resize', this.onResize);
  }

  unmounted() {
    removeEventListener('resize', this.onResize);
  }

  onResize() {
    const widthInRem = 100;
    const widthInPx = widthInRem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    const newSlidesPerView = window.innerWidth >= widthInPx ? 2 : 1;

    if (this.slidesPerView !== newSlidesPerView) {
      this.slidesPerView = newSlidesPerView;
    }
  }

  loadData() {
    if (this.mensa === null) {
      fetch(`https://api.ericbiedert.de/mensa/menu?mensa=${encodeURI(this.mensaName)}`)
        .then(res => res.json())
        .then((data: Mensa | { error: string }) => {
          if ("error" in data) {
            throw new Error(data.error);
          }
          this.mensa = data;
          this.lastUpdated = new Date().toLocaleString();
          this.saveToStorage();
        })
        .catch(err => {
          this.mensa = null;
          this.error = err.message;
        });
    }
  }
  
  saveToStorage() {
    const date = Date.now();
    const data = { date, mensa: this.mensa }
    localStorage.setItem(`mensa#${this.mensaName}`, JSON.stringify(data));
  }

  loadFromStorage() {
    const data = JSON.parse(localStorage.getItem(`mensa#${this.mensaName}`) ?? 'null');
    if (data !== null) {
      const { date, mensa } = data;
      const now = Date.now();
      // Max age of cache is 6 hours
      if (now - date < 6 * 60 * 60 * 1000) {
        this.mensa = mensa;
        this.lastUpdated = new Date(date).toLocaleString();
      } else {
        localStorage.removeItem(`mensa#${this.mensaName}`);
      }
    }
  }

  getMealName(meal: Meal) {
    if (this.getAllergens(meal) !== null) {
      const index = meal.name.toLowerCase().indexOf("allergen");
      return meal.name.substring(0, index);
    }
    return meal.name;
  }

  getAllergens(meal: Meal): string | null {
    const index = meal.name.toLowerCase().indexOf("allergen");
    if (index === -1) {
      return null;
    }
    return meal.name.substring(index).replaceAll(",", ", ");
  }

  isVegan(meal: Meal): boolean {
    return meal.name.toLowerCase().includes("vegan");
  }

  hasExtra(meal: Meal) {
    return this.isVegan(meal) || this.getAllergens(meal) !== null;
  }

  onBack() {
    this.mensa = null;
    this.error = null;
    this.hideSpinner = true;
  }

  refresh() {
    this.mensa = null;
    this.error = null;
    this.loadData();
  }
}
