import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import SelectMensaView from '../views/SelectMensaView.vue'
import MenuView from '../views/MenuView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'select',
    meta: {
      hasBack: false,
    },
    component: SelectMensaView
  },
  {
    path: '/menu/:mensaName',
    name: 'menu',
    props: (route) => ({ mensaName: route.params.mensaName }),
    meta: {
      hasBack: true,
    },
    component: MenuView,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/MenuView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  to.meta.title = to.params.mensaName ?? "Mensaplan";
  next();
});

export default router
